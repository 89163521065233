.taxi-form-single {
  font-size: 0.75rem;
}
.taxi-form-single .card {
  background: #fff;
  border: none;
  box-shadow: none;
  color: inherit;
  margin: 0 0 1rem;
  border-top: none;
}
.taxi-form-single .form-group {
  margin-bottom: 0.5rem;
}

.taxi-form-single .form-group label {
  font-size: 0.85rem;
}

.taxi-form-single .form-group legend {
  margin-bottom: 0.2rem !important;
}

.taxi-form-single .modal__body {
  margin-bottom: 1rem;
  padding: 1.4375rem;
}
.taxi-form-single .card__body {
  display: none;
}
.taxi-form-single .card__body.u--border-top {
  display: block;
  margin-bottom: 0;
  padding: 0;
}
.taxi-form-single .card__footer {
  font-size: 0.75rem;
}

.taxi-form-single .button--theme-cta {
  background: #00262b;
  /*value set in px since the requirement was to match current prod font sizes for the experiment */
  font-size: 18px;
}
.taxi-form-single .button--theme-cta:hover {
  background: #2d494e;
}
.taxi-form-single .button--theme-cta:disabled {
  opacity: 0.4;
}
.taxi-form-single .button--theme-cta:focus {
  border: none !important;
  background: #00262b !important;
  outline: none !important;
  box-shadow: none !important;
}
.taxi-form-single .button--theme-cta:focus::before,
.taxi-form-single .button--theme-cta:focus::after {
  content: none !important;
}

/* THIS IS A TEMP FIX TO MAKE THE CHECKBOXES ALIGN */
.taxi-form-single .u--border-top {
  border-top: none !important;
}
.taxi-form-single .u--jsb,
.taxi-form-single
  .u--justify-space-between[aria-label="Email"]
  .form-group:first-of-type:not(fieldset) {
  display: none !important;
}
/* END OF TEMP FIX FOR ALIGNMENT */
